html,
body {
  min-height: 100%;
  background-color: #fb4940;
  font-family: 'Barlow', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1 {
  display: none;
}

a {
  text-decoration: none;
}

section {
  display: block;
}

.background {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 0;
}

.background svg {
  width: 80vh;
  height: 80vh;
  position: relative;
  left: 50%;
  margin-left: -40vh;
  bottom: -4px;
}

.heading {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  line-height: 1.25;
  padding: 32px;
  cursor: default;
  z-index: 2;
}

.heading span {
  position: relative;
  display: inline-block;
}

.heading span:before,
.heading span:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  top: 50%;
  margin-top: -0.5px;
  background: #fff;
}

.heading span:after {
  right: 0;
  background: #fff;
  transition: width 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.heading span:hover:before {
  background: #fff;
  width: 100%;
  transition: width 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.heading span:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

.logo {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.logo svg {
  height: 15vh;
  margin: 0 48px;
}

.logo path,
.social path {
  fill: #ffffff;
}

.social {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 32px;
  z-index: 2;
}

.social a {
  margin-left: 20px;
}

.social svg {
  width: 24px;
  height: 24px;
}

.social a:hover svg {
  transform: scale(1.1);
  filter: drop-shadow(0 2px 0 rgba(0, 0, 0, 0.1));
}
